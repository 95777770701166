@font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: 400;
    src: url('GT-America-Standard-Regular.otf') format('opentype'),
        url('GT-America-Standard-Regular.woff2') format('woff2'),
        url('GT-America-Standard-Regular.woff') format('woff'),
        url('GT-America-Standard-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: 500;
    src: url('GT-America-Standard-Medium.otf') format('opentype'),
        url('GT-America-Standard-Medium.woff2') format('woff2'),
        url('GT-America-Standard-Medium.woff') format('woff'),
        url('GT-America-Standard-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: 700;
    src: url('GT-America-Standard-Bold.otf') format('opentype'),
        url('GT-America-Standard-Bold.woff2') format('woff2'),
        url('GT-America-Standard-Bold.woff') format('woff'),
        url('GT-America-Standard-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: 900;
    src: url('GT-America-Extended-Black.otf') format('opentype'),
        url('GT-America-Extended-Black.woff2') format('woff2'),
        url('GT-America-Extended-Black.woff') format('woff'),
        url('GT-America-Extended-Black.ttf') format('truetype');
}
